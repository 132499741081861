@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
.logo-top{
    /* margin-top:80%; */
    transition: all 700ms;
}
ion-content{
    /* --ion-background-color: #e3e3e3;
    --background: linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 74%); */
    font-family: 'Montserrat', sans-serif !important;
}
html.ios {
    --ion-default-font: 'Montserrat', sans-serif !important;
  }
  html {
    --ion-font-family: 'Montserrat', sans-serif !important;
  }
  body.react-confirm-alert-body-element {
    overflow: hidden;
  }
  
  .react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
  
  .react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(255, 255, 255, 0.9);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  }
  
  .react-confirm-alert-body {
    /* font-family: Arial, Helvetica, sans-serif; */
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
  }
  
  .react-confirm-alert-svg {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .react-confirm-alert-body > h1 {
    margin-top: 0;
  }
  
  .react-confirm-alert-body > h3 {
    margin: 0;
    font-size: 16px;
  }
  
  .react-confirm-alert-button-group {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
  }
  
  .react-confirm-alert-button-group > button {
    outline: none;
    background: #333;
    border: none;
    display: inline-block;
    padding: 6px 18px;
    color: #eee;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
  }
  
  @-webkit-keyframes react-confirm-alert-fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @-moz-keyframes react-confirm-alert-fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @-o-keyframes react-confirm-alert-fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes react-confirm-alert-fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }  
/* h1,h2,h3,h4,h5,h6{
    font-family: 'Montserrat', sans-serif !important;
}
.item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h{
    font-family: 'Montserrat', sans-serif !important;
} */
/* body{
    
} */
/* ion-select{
    --padding-start: 8px;
    --padding-end: 8px;
}
ion-input{
    --padding-end: 8px !important;
    --padding-start: 8px !important;
} */
ion-item::part(native) {
    --padding-end: 8px !important;
    --padding-start: 8px !important;
}
.label-stacked.sc-ion-label-ios-h, .label-floating.sc-ion-label-ios-h{
    color: #717171 !important;
}
.main-content{
    opacity: 0;
    /* transform: translate3d(50px, 0, 0);  */
    transition: 1s all ease-in-out;
    /* display: none; */
    /* animation: fadeIn 3s;  */
}
.main-content .token-generator {
    margin-top: 50px;
    max-width: 20%;
    height: 65px;
    margin: 43px auto 20px;
    background-color: #359ead;
    color: #fff;
    border-radius: 8px;
}
.token-generator{
    height:150px;
    margin-top: 10px ;  
    border: 0 !important;
    border-color: hsla(32,40%,84%,.6) !important;  
    --border-style: inherit;
}
.reg_blk h2 {
    font-size: 23px;
    font-weight: 600;
    margin: 0px 0 5px;
}
.reg_blk  p {
    line-height: 21px;
    font-size: 14px;
    margin: 0 0 10px;  
    color: #717171;
}
.register_logo .hdr_icon{
    margin-top: 9px; 
    text-align:right;
}
.regster_input{
    display: flex;
    justify-content: center;
    align-items: center;
}
.regster_input .item-input{
    width: 50% !important;
    border: 1px solid #dfdfdf;
    border-radius: 60px;
}
.bottom_save{
    display: flex;
    align-items: center;
    justify-content: center;
}
.bottom_save .button-native{
    text-align: center;
    border: 1px solid  hsla(32,40%,84%,.6);
    padding: 12px 50px;
    margin: 15px 0 12px 0;
    font-size: 14px;
    background-color: hsla(32,40%,84%,.6);
    color: #000;
    border-radius: 25px;
    font-weight: 300;
}

/* .content1{ */
    
     /* transition: 1s all ease-in-out;  
    opacity: 1; */
/* } */
.dispnone{
   display:none;
}
.register_logo{
	margin-top: 15px !important;
	margin-bottom: 15px !important;
}
.register_logo img{
   width: 20% !important;
}
@-webkit-keyframes fadeInLeft {
    from {
        opacity:0;
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -o-transform: translatex(-10px);
        transform: translatex(-10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@-moz-keyframes fadeInLeft {
    from {
        opacity:0;
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -o-transform: translatex(-10px);
        transform: translatex(-10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@keyframes fadeInLeft {
    from {
        opacity:0;
        -webkit-transform: translatex(-100px);
        -moz-transform: translatex(-100px);
        -o-transform: translatex(-100px);
        transform: translatex(-100px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
.content1 {
    -webkit-animation-name: fadeInLeft;
    -moz-animation-name: fadeInLeft;
    -o-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    -o-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -o-animation-duration:0.5s;
    animation-delay: 0.3s;
}
.personal_msg p {
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    background: #ffff;
    color: #359ead;
    padding: 30px 20px;
    box-shadow: 0px 0px 15px 1px #d9d9d9;
    border-radius: 10px;
}
@media screen and (min-width: 100px) and (max-width: 480px){
.rgster_txt h2 {
	font-size: 15px;
	margin: 15px 0 3px 0;
	font-weight: 400;
	color: #737373;
}
.upcmg_txt h2 {
    font-size: 16px;
    margin: 30px 0 10px 0;
    font-weight: 600;
    color: #4a4a4a;
    border-bottom: 1px solid #bfbfbf;
    padding: 0 0 10px 0;
}
.main-content .token-generator {
	font-size: 13px;
	border: 1px solid #b9b9b9 !important;
	font-weight: 400;
	color: #fff;
	text-transform: capitalize;
}
.book_list{
	width: 100%;
	background: inherit;
	margin: 0;
	padding: 0;
}
.book_list ul{
    margin:0;
    padding: 0 8px;
}
.book_list ul li {
    list-style: none;
    font-size: 15px;
    font-weight: 400;
    color: #4a4a4a;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
}
.book_list ul li span{
    color: #0069a8;
}
.bottom_save button {
	width: 100%;
    border-radius: 7px;
    font-weight: 300;
    padding: 15px 50px;
}
.book_list ul li .approve{
    color: green;
}
.book_list2 {
	border-top: 1px solid #bfbfbf;
	padding: 15px 0 12px 0;
}
.appoint_button button{
    background-color:#a61c00 !important;
    color:#fff !important;
    border-radius: 7px !important;
}
.cancel_btn button{
    margin:0 10px !important;
    border-radius: 7px !important;
}
.cancel_btn .cncel_btn {
    background-color: #a61c00 !important;
    color: #fff !important;
    border-radius: 7px !important;    
}
.cancel_btn .acess_btn {
    background-color: #6aa84f !important;
    color: #fff !important;
    border-radius: 7px !important;    
}
.gender_form{
    background: inherit;
    margin: 0px 0px 45px 0px;
}
.gndr_frm {
    box-shadow: 0 0 15px 1px #cfcfcfc2;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #efefef;
    margin: 30px 0px 30px 0px;
}
.gender_form .item-lines-default{
    /* border: 1px solid #c8c7c7; */
    margin: 0 0 20px 0;
    /* box-shadow: inherit; */
    border-bottom: 0;
}
.main-content .token-generator {
    max-width: 100% !important;
    font-size: 25px;
    min-height: 280px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 30px;
    width: 100%;
    border-radius: 20px;
    margin: 10px auto 10px;
    box-shadow: 7px 1px 20px #a6a3a3;
}
.main-content .token-generator span {
    font-size: 20px;
    text-transform: lowercase;
    font-weight: 300;
}


}
.big-btn{
    max-width: 100% !important;
    font-size: 25px;
    min-height: 100px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 30px;
    width: 100%;
    border-radius: 8px;
    margin: 10px auto 10px;
    background-color: #359ead;
    box-shadow: 7px 1px 20px #a6a3a3;
    color: #fff;
    border: 1px solid #b9b9b9 !important;
    --border-style: inherit;
}
.footer_data{
    font-size: 11px;
    display: flex;
    justify-content: center;
    margin-bottom: 11px;
}
.footer_data a {
    padding: 0 10px 0 0;
    text-decoration: none;
    color: #999;
    font-size: 14px;
}
.prefooter {
    font-size: 10px;
    text-align: center;
    color: #666;
    line-height: 16px;
    letter-spacing: 0.2px;
}

