

ion-datetime-button::part(native) {
    background: none;
    font-size: 15px;
    font-weight: 500;
    color: #242424;
    padding-bottom: 13px;
    padding-top: 8px;
}
/* @font-face {   
    font-family: 'Montserrat' ; 
    src: url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700,900|Ubuntu:400,500,700');
  } */
/* 
.main-content .purpose_btns{
    height: 100px;
} */
.mains-btn ul{
     margin-top:0;
     padding: 0;
}
.mains-btn ul li{
    list-style: none;
}
.mains-btn .button {
    margin-top: 15px;
}
.mains-btn ul li span{
    padding-left: 15px;
}
.mains-btn {
    height: 300px;
    overflow-y: scroll;
    padding-right: 7px;
}
/* width */
/* ===== Scrollbar CSS ===== */
  /* Firefox */

  /* Chrome, Edge, and Safari */
.mains-btn::-webkit-scrollbar {
    width: 7px;
  }
.mains-btn::-webkit-scrollbar-thumb {
    background-color: #757575;
    border-radius: 10px;
    border: 3px solid #757575;
  }
.timer_button button, .purpose_btns button {
    text-align: center;
    padding: 15px 10px;
    width: 100%;
    margin: 0 0 9px 0;
    font-size: 17px;
    background: #efeeee;
    color: #000;
    font-weight: 400;
    border-radius: 4px;
}
.purpose_btns button {
    padding: 48px 10px !important;
    margin: 0 0 18px 0;
}
.timer_btn_clr button,.purpose_btn_clr button{
    background-color:  rgb(255 164 145);
    color: white;
}
.custom_btn{
    text-align: right;
    margin-right: 36%;
}
.custom_btn button {
    text-align: center;
    border: 1px solid  #359ead;
    padding: 18px 30px;
    margin: 4px 0 2px 0;
    width: 100%;
    font-size: 16px;
    background-color: #359ead;
    color: #fff;
    border-radius: 7px;
}
  /* Track */
/* .mains-btn::-webkit-scrollbar-track {
    background: #f1f1f1;
  } */
  
  /* Handle */
/* .mains-btn::-webkit-scrollbar-thumb {
    background: #888;
  } */
  
  /* Handle on hover */
/* .mains-btn::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */
#time-slting{
    justify-content: left;
    font-size: 23px;
    margin-bottom:5px;
}
/* .logo-top{
    margin-top: 32px !important;
    background: #fff;
} */
.logo-top img{
    width:62%;
}
.hdr_nav{
    text-align: center;
    margin: 26px 0 0 0;
}
.hdr_nav ul{
    margin: 0;
    padding: 0;
}
.hdr_nav ul li{
    list-style: none;
    display: inline-block;
    margin: 0 3px;
}
.hdr_nav ul li span {
    font-size: 14px;
    font-weight: 400;
    color: #080808;
    text-transform: uppercase;
    align-items: center;
    text-decoration: none;
    cursor:pointer;
}
.hdr_nav ul li span.active{
    color: rgb(255, 139, 123);
}
.hdr_nav ul li svg {
    width: 14px;
    vertical-align: bottom;
    margin-left: 10px;
}
.mains_grid{
    margin:0;
    height: 100%;
    width: 100%;
    padding: 0px 15px 0px 15px;
    /* background: #fcf5ea !important; */
}

.select_txt h2 {
    font-size: 23px;
    font-weight: 400;
    color: #222222;
}
.scroll-y {
    overflow: inherit !important;
}

.slots_list{
    padding-bottom: 0 !important;
}
.slots_list  #time-slting {
    /* padding: 22px 0 9px 0; */
    font-size: 18px;
    border-bottom: 1px solid #e3e3e3;
}     

.main_calender {
    margin: 0 auto;
    max-width: 65%;
    display: flex;
    gap: 15px;
}
.calender_image{
    padding: 0;
}
.calender_image img {
    width: 100%;
    height: 240px;
    object-fit: cover;
}
.calender_lft {
    background-color: #fff;
    padding: 0;
}
.calender_rt {
    background-color: #fff;
    display: flex;
    padding: 0;
}
.calender_last{
    padding-top:0 ;
}
.calender_last h2{
    font-size: 15px;
    font-weight:500;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 15px;
    margin-top: 0;
    margin-bottom: 0;
}
.calender_lft h2 {
    font-size: 20px;
    font-weight: 400;
    color: #464646;
    padding: 0 20px;
    margin: 0;
}
.calender_lft p {
    font-size: 16px;
    font-weight: 500;
    color: #363637;
    padding: 0 20px;
    margin: 0;
    font-style: italic;
}
.main_purpose{
    max-width: 55% !important;
    margin: 0 auto !important;
}
.bottom_save{
    display: flex;
    align-items: center;
    justify-content: center;
}
.bottom_save button{
    text-align: center;
    border: 1px solid  hsla(32,40%,84%,.6);
    padding: 12px 50px;
    margin: 15px 0 12px 0;
    font-size: 14px;
    background-color: hsla(32,40%,84%,.6);
    color: #000;
    border-radius: 25px;
    font-weight: 300;
}

@media screen and (min-width:100px) and (max-width:480px){
.hdr_nav{
    display: none;
}
.logo-top .ion-text-center{
    width: 100% !important;
    max-width: 100% !important;
    flex:inherit !important;
}
.logo-top {
    /* margin-top: 30px !important; */
    margin-bottom: 5px !important;
}
.select_txt h2 {
    font-size: 21px;
    margin-top: 8px;
}
.logo-top img {
    width: 50%;
}
.main_calender {
    margin: inherit;
    max-width: 100%;
    display: inherit;
}
.calender_lft {
    max-width: 100% !important;
    flex: inherit !important;
    width: 100% !important;
    display: flex;
    margin: 0 0 17px 0;
}
.calender_lft .calender_txt{
    max-width: 100% !important;
    flex: inherit !important;
    width: 60% !important;
}
.calender_rt .slots_list{
    max-width: 100% !important;
    flex: inherit !important;
    width: 100% !important;
    padding: 0;
    margin-bottom: 15px;
}
.calender_lft h2 {
    padding: 0 7px;
}
.calender_lft p {
    padding: 0 7px;
}
.custom_btn{
    text-align: center;
    margin-right: inherit;
}
.custom_btn button {
	margin: 0 0 24px 0;
}
#datetime {
    padding: 0;
}
.calender_rt .calender_last {
    max-width: 100% !important;
    flex: inherit !important;
    width: 100% !important;
    background: #ffffff;
    padding: 10px 25px;
    border-radius: 7px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px 1px #ccc;
}
.calender_rt {
    display: inherit !important;
}
.main_purpose {
    max-width: 100% !important;
    margin: 22px auto 0 !important;
}
.purpose_btns button {
    padding: 23px 10px !important;
    margin: 0 0 12px 0;
}
.register_logo img {
	width: 50% !important;
    cursor: pointer;
}
.regster_input .item-input {
	width: 100% !important;
}
.main_tokens {
	text-align: center;
	display: flex;
	align-items: center;
	height: 61%;
	width: 100%;
}


}
@media screen and (max-width:768px){
    .mob_screen{
        display:none;
    }
}